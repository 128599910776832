.submitButton {
    display: block;
    margin: 0 2rem 4rem;
    width: calc(100% - 4rem);
    animation: growIn .2s cubic-bezier(0.42, 0, 0.56, 1.15) 0s 1 normal forwards;
}

@keyframes growIn {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

