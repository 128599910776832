.container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 7em;
  > nav {
    width: 100%;
  }
}

.heading {
  font-size: 5rem;
  margin-top: 1rem;
  margin-bottom: 0;
}

.cards {
  margin-top: auto;
  width: 15rem;
}

.subtitle {
  font-size: 1.8rem;
  margin-bottom: 3.5rem;
}

.createButton {
  font-size: 1.7rem;
  background-color: var(--color-link);
  border: 2px solid var(--color-link);
  color: var(--color-bg);
  border-radius: var(--border-radius);
  font-weight: 600;
  margin: 0;
  padding: 1.2rem;
  width: 25rem;
}

.codeInputLabel {
  margin-left: auto;
  margin-right: auto;
}

.codeInput {
  margin: 0 auto;
  line-height: 2;
  font-size: 1.7rem;
  padding-left: 2rem;
  padding-right: 2rem;
  text-align: center;
  width: 25rem;
}

.joinButton {
  margin-top: 2rem;
  margin-bottom: auto;
  padding: 1rem 3rem;
  transition: transform .2s cubic-bezier(0.42, 0, 0.56, 1.15);
  transform: scale(0);
  pointer-events: none;
  visibility: hidden;
}

.joinButtonVisible {
  transform: scale(1);
  visibility: visible;
  pointer-events: all;
}

.or {
  margin: 1rem 0;
}
