.table {
    width: 25rem;
    td {
        min-width: 11rem;
        &:last-child {
            font-weight: bold;
            text-align: right;
        }
    }
}

