.input {
    font-size: 1.5rem;
    line-height: 2;
    padding: 1.5rem;
    min-height: 5rem;
    border: 0.1rem solid var(--color-bg-secondary);
    margin-top: 2rem;
    width: 45rem;
    max-width: 80vw;
    border-radius: var(--border-radius);
    color: var(--color-text);
    line-height: 1.25;
    text-align: left;
    overflow: hidden;
    white-space: pre-wrap;

    a {
        word-break: break-all
    }

    &:empty:before{
        text-align: center;
        content: attr(placeholder);
        font-style: italic;
        pointer-events: none;
        font-size: 1.5rem;
        font-weight: light;
        display: block;
        color: var(--color-text-secondary);
    }
}

.tip {
    font-size: 1.2rem;
    margin-top: 2rem;
    margin-bottom: 0;
    pre {
        margin: 0;
        padding: 0.5rem;
    }
}
