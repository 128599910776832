.root {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    padding-bottom: 0;
    max-width: none;
}

.heading {
    margin-top: auto;
    margin-bottom: 1.5rem;
}

.cards {
    width: 100%;
    margin: 0;
    padding: 2.5rem 0 0;
    max-height: 50vh;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
}

.row {
    display: flex;
    justify-content: center;
    flex-direction: column-reverse;
    @media (min-width: 640px) {
        flex-direction: row;
    }
}
